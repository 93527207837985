.Socials {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	bottom: 0;
	left: 64px;
	z-index: 1;

	a {
		height: 22px;
		width: 34px;
		margin-bottom: 23px;
	}
}

.stripe {
	background: #ffffff;
	width: 1px;
	height: 120px;
	margin-top: 15px;
}

svg {
	margin-bottom: 30px;
	width: 34px;
	height: 34px;
	fill: #ffffff;
	padding: 6px;
	position: relative;
}

.s1:hover {
	fill: #813bff;
}

.s1:focus {
	fill: #813bff;
}

.s2:hover {
	fill: #0058e0;
}

.s2:focus {
	fill: #0058e0;
}

.s3:hover {
	fill: #59d3ff;
}

.s3:focus {
	fill: #59d3ff;
}

.s4:hover {
	fill: #ff3c94;
}

.s4:focus {
	fill: #ff3c94;
}

@media only screen and (max-width: 1050px) {
	.Socials {
		left: auto;
		right: 40px;
	}

	.stripe {
		height: 90px;
	}
}

@media only screen and (max-width: 730px) {
	.Socials {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		bottom: -74px;
		left: 0;
		// border-top: solid 1px #fe3e55;
		z-index: 2;

		a {
			margin: 35px 23px 50px 20px;
		}
	}

	.stripe {
		background: #cecece00;
		width: 0px;
		height: 0px;
		margin-top: 0px;
		position: absolute;
	}

	svg {
		width: 34px;
		height: 34px;
		fill: rgb(255, 255, 255);
		position: relative;
	}
}
