body {
  margin: 0;
  padding: 0 220px;
  line-height: 1.3;
  background: #000000 fixed;
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1050px) {
  body {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 730px) {
  body {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 420px) {
  body {
    padding: 0 22px;
  }
}
