h1 {
  font-size: 70px;
  font-family: "Overpass";
  margin-bottom: 5px;
  color: #ffffff;
}

.title-section {
  width: 100%;
  min-height: 100vh;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  padding-bottom: 130px;
}

.introduction {
  font-size: 18px;
  color: #adadad;
  margin-bottom: 10px;
  margin-left: 2px;
  font-family: "Overpass Mono";
}

a:focus {
  outline: none;
}

p {
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 10px;
}

.description-title {
  margin-top: 35px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  font-family: "Overpass";
  max-width: 540px;
}

.sub-header-title {
  color: #ffffff;
}

.contact-button {
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 0;
  font-family: "Overpass Mono";
  font-size: 16px;
  cursor: pointer;
  margin-right: 30px;
  padding: 18px 30px;
  background: rgba(0, 0, 0, 0);
  margin-top: 60px;

  &:focus {
    outline: none;
  }
}

.b1:focus {
  background: #007350;
}

.b1:hover {
  background: #007350;
}

.b2:focus {
  background: #00538c;
}

.b2:hover {
  background: #00538c;
}

@media only screen and (max-width: 1050px) {
  h1 {
    font-size: 60px;
  }

  p {
    font-size: 16px;
  }

  .description-title {
    margin-top: 35px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 730px) {
  h1 {
    font-size: 50px;
  }

  p {
    font-size: 16px;
  }

  .description-title {
    margin-top: 35px;
    font-weight: 300;
    width: 90%;
  }

  .introduction {
    font-size: 14px;
  }
}

@media only screen and (max-width: 650px) {
  h1 {
    font-size: 47px;
  }

  body {
    padding: 48px;
  }

  p {
    font-size: 16px;
  }

  .description-title {
    margin-top: 35px;
    font-weight: 300;
    width: 90%;
  }

  .title-section {
    margin: auto;
    margin-top: -20px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 440px) {
  h1 {
    font-size: 32px;
  }

  p {
    font-size: 14px;
  }

  .description-title {
    margin-top: 35px;
    font-weight: 300;
    font-size: 17px;
    width: 98%;
  }

  .introduction {
    font-size: 12px;
  }

  .title-section {
    margin: auto;
    margin-top: -40px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
  }

  .sub-header-title {
    font-size: 32px;
  }

  .contact-button {
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    margin-right: 30px;
    padding: 15px 18px;
    background: rgba(0, 0, 0, 0);
    margin-top: 50px;
  }
}

@media only screen and (max-width: 420px) {
  h1 {
    font-size: 37px;
  }

  .title-section {
    margin: auto;
    margin-top: 5px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
  }

  body {
    padding: 25px;
  }

  .introduction {
    font-size: 16px;
  }

  .sub-header-title {
    font-size: 38px;
  }

  .orb {
    bottom: -600px;
    right: -275px;
  }
}
