.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}
.headroom-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 0px;
  right: 0;
  z-index: 10;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}
.headroom--scrolled {
  transition: transform 0.2s ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-150%);
  transition: transform 0.2s ease-in-out;
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
  transition: transform 0.2s ease-in-out;
}

.nav-wrapper {
  height: 56px;
}

.nav-wrapper-mobile {
  width: 101vw;
  height: 70px;
  position: fixed;
  top: -100px;
  left: 0;
  background: #000000;
  backdrop-filter: blur(12px);
  z-index: 3;
  box-shadow: 0 5px 15px rgb(0, 0, 0);
}

.blur-hide {
  width: 105vw;
  height: 105vh;
  position: fixed;
  left: 0;
  top: 0;
  backdrop-filter: blur(0px) brightness(100%);
  backface-visibility: hidden;
  z-index: -2;
  transition: all 200ms ease-out;
}

.navigation-ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 0px;
  background: #000000;
  transition: all 200ms ease-out;
  padding: 50px 60px 20px 0;
  width: 100%;

  li {
    list-style: none;
    cursor: pointer;
    margin: 18px 0 46px 20px;
    font-size: 15px;
    font-family: "Overpass Mono";
    padding: 5px 10px 3px;
    color: #ffffff;
    border-bottom: 2px solid rgba(65, 132, 255, 0);

    span {
      margin-right: 8px;
      font-size: 12px;
      color: #8f8f8f;
    }
  }
}

.navTop {
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0);
}

.hamburger-menu {
  visibility: hidden;
  position: absolute;
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  right: 32px;
  top: 15px;
  cursor: pointer;

  div {
    height: 3px;
    width: 40px;
    border-radius: 0;
    background: #ffffff;
    margin: 3.5px;
  }

  .bar-2 {
    width: 35px;
  }

  .bar-3 {
    width: 30px;
  }
}

.logo p {
  color: #000000;
  font-family: "Overpass Mono";
  position: absolute;
  top: 8px;
  cursor: default;
  font-size: 10px;
  left: 62px;
  width: 80px;
  letter-spacing: 6px;
  line-height: 18px;
  position: fixed;
}

@media only screen and (max-width: 1050px) {
  .navigation-ul {
    margin-right: 10px;
    padding-right: 30px;
  }

  .logo p {
    color: #ffffff;
    position: absolute;
    top: 8px;
    font-size: 10px;
    left: 82px;
    width: 80px;
    letter-spacing: 7px;
    line-height: 18px;
    position: fixed;
  }
}

@media only screen and (max-width: 730px) {
  .navigation-ul {
    margin-right: 0px;
    box-shadow: -10px 0px 15px rgba(0, 0, 0, 0.199);
    width: 100%;
    background: none;
    position: fixed;
    left: 110vw;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid white;
    height: 100vh;
    min-height: 400px;
    background: #000000;
    padding-bottom: 120px;
    padding-left: 17%;

    li {
      font-size: 18px;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      list-style: none;
      margin: 0px;
      margin-bottom: 30px;

      span {
        margin-right: 0px;
        font-size: 15px;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .nav-wrapper-mobile {
    top: -1px;
  }

  .navigation-ul-active {
    left: 50%;
  }

  .hamburger-menu {
    visibility: visible;
    -webkit-tap-highlight-color: transparent;
    z-index: 11;
  }

  .blur-show {
    backdrop-filter: blur(8px);
    backface-visibility: hidden;
    z-index: 3;
  }

  .hamburger-menu-active {
    align-items: center;

    .bar-1 {
      height: 3px;
      width: 40px;
      border-radius: 5px;
      background: #ffffff;
      margin: 3.5px;
      position: relative;
      top: 10px;
      transform: rotate(-45deg);
    }

    .bar-2 {
      width: 0;
    }

    .bar-3 {
      height: 3px;
      transform: rotate(45deg);
      width: 40px;
      border-radius: 5px;
      background: #ffffff;
      position: relative;
      bottom: 10px;
      margin: 3.5px;
    }
  }

  .logo p {
    position: absolute;
    color: #000000;
    position: absolute;
    top: 13px;
    left: 40px;
    font-size: 8px;
    width: 80px;
    letter-spacing: 6px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 650px) {
  .nav-wrapper {
    height: 0px;
  }

  .navigation-ul {
    padding-left: 15%;
  }
}

@media only screen and (max-width: 420px) {
  .navigation-ul {
    margin-right: 0px;
    box-shadow: -10px 0px 10px rgba(17, 17, 17, 0.2);
    width: 100%;
    height: 73px;
    position: fixed;
    left: 110vw;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background: #000000;
    padding-bottom: 120px;
    padding-left: 23%;
  }

  .hamburger-menu {
    right: 15px;
  }

  .logo p {
    position: absolute;
    color: #000000;
    position: absolute;
    top: 13px;
    left: 23px;
    font-size: 8px;
    width: 80px;
    letter-spacing: 6px;
    line-height: 15px;
  }

  .navigation-ul-active {
    left: 27%;
  }
}
